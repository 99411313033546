<template>
  <FocusTrap>
    <div  id="mycard" class="card">

      <div class="card-header" style="padding: 0px">
        <div class="page-header page-header-light" >
          <div class="page-header-content header-elements-lg-inline" >
            <div class="page-title d-flex" style="padding: 0px;">
              <h6>
                <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Stitching </span> - List
              </h6>
              <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
            </div>

            <div class="header-elements d-none">
              <form action="#">
                <div class="form-group">

                  <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                    <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                    <div class="form-control-feedback">
                      <i class="icon-search4"></i>
                    </div>

                    <div class="input-group-append position-static">
                      <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterWindow">
                        <i class="icon-gear"></i>
                      </button>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="mytable"
                 class="table  table-hover  table-bordered table-condensed table-columned"
                 data-search="false"
                 data-pagination="true"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-detail-view="true"
                 data-detail-formatter="stitchingDetailFormatter"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-formatter="runningFormatter" data-width="75" >S.No</th>
              <th data-field="doc_date" data-width="110" data-formatter="dateFormatter"  data-sortable="true">Date</th>
              <th data-field="doc_no" data-width="100" data-sortable="true">Doc No</th>
              <th data-field="shift.name" data-sortable="true">Shift</th>
              <th data-field="tailor.name" data-sortable="true">Tailor</th>
              <th data-field="total_pcs" data-sortable="true">Pcs</th>
              <th data-field="remarks" data-sortable="true">Remarks</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
          <a href="#" class="dropdown-item" ><i class="icon-database-edit2" style="color: #2d7330"></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" ><i class="icon-database-remove" style="color: orangered"></i>Remove</a>
<!--          <div class="dropdown-divider"></div>-->
<!--          <a href="#" class="dropdown-item" ><i class="icon-stamp"></i>Send To QC</a>-->
        </div>
      </div>
      <!-- End of Context Menu -->

      <FocusTrap>
        <vmodal name="stitching_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" height="66%" :draggable="true" >
          <Stitching v-if="voucher.id > 0" v-on:stitching_window_closed="closeModal" v-bind:myvoucher="voucher" v-on:stitching_saved="loadData" ></Stitching>
        </vmodal>
      </FocusTrap>


      <!-- print preview modal -->
      <div id="fsPrintPreview"
           class="modal animated bounceIn"
           tabindex="-1"
           role="dialog"
           aria-labelledby="myModalLabel"
           aria-hidden="true">

        <!-- dialog -->
        <div class="modal-dialog modal-full">

          <!-- content -->
          <div class="modal-content" style="padding: 0px;">

            <!-- body -->
            <div class="modal-body">
              <ReportViewer v-if="reppath" v-bind:reportfilename="filename" v-bind:report_url="reppath"></ReportViewer>
            </div>
            <!-- body -->

          </div>
          <!-- content -->

        </div>
        <!-- dialog -->

      </div>
      <!-- print preview modal -->


      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->


      <FocusTrap>
        <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterfilterwindow_visible">
          <form>
            <div class="card">
              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Filter</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" ref= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                  </div>

                  <label class="col-md-3 col-form-label">Upto</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Tailor:</label>
                  <div class="col-md-9">
                    <select id="cmbMachine" class="form-control" v-model="emp_id" >
                      <option value="0" selected>All</option>
                      <option v-for="camp in emps" v-bind:value="camp.id">
                        {{ camp.name }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import Stitching from '@/views/fms/stitching/StitchingForm.vue'
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'StitchingView',
  store,
  components: {
    Stitching,
    FabButton,
    moment
  },
  data () {
    return {
      isActive: false,
      voucher: JSON.parse('{"id":0,"finyear":2000,"status":"Active","cmp_id":1,"shift_id":1,"emp_id":1,"shift":{"id":0,"type":0,"name":""},"emp":{"id":0,"name":""},"doc_date":"0001-01-01","list":[],"total_pcs":0,"remarks":""}'),
      mytable: {},
      reppath: '',
      filename:'',
      shift: 0,
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      emp_id : 0,
      emps : [],
    }
  },
  created () {
    Stimulsoft.Base.StiLicense.Key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHn0s4gy0Fr5YoUZ9V00Y0igCSFQzwEqYBh/N77k4f0fWXTHW5rqeBNLkaurJDenJ9o97TyqHs9HfvINK18Uwzsc/bG01Rq+x3H3Rf+g7AY92gvWmp7VA2Uxa30Q97f61siWz2dE5kdBVcCnSFzC6awE74JzDcJMj8OuxplqB1CYcpoPcOjKy1PiATlC3UsBaLEXsok1xxtRMQ283r282tkh8XQitsxtTczAJBxijuJNfziYhci2jResWXK51ygOOEbVAxmpflujkJ8oEVHkOA/CjX6bGx05pNZ6oSIu9H8deF94MyqIwcdeirCe60GbIQByQtLimfxbIZnO35X3fs/94av0ODfELqrQEpLrpU6FNeHttvlMc5UVrT4K+8lPbqR8Hq0PFWmFrbVIYSi7tAVFMMe2D1C59NWyLu3AkrD3No7YhLVh7LV0Tttr/8FrcZ8xirBPcMZCIGrRIesrHxOsZH2V8t/t0GXCnLLAWX+TNvdNXkB8cF2y9ZXf1enI064yE5dwMs2fQ0yOUG/xornE";

  },
  mounted () {
    const self = this;
    this.$data.mytable = $('#mytable');
    this.$data.mytable.bootstrapTable();

    self.loadEmps();

    $('[data-toggle="popover"]').popover();
    $('#mytable').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {


        let id = parseInt($(row.children('*')[1]).text());

        if ($(e.target).text() === "Refresh") {
          self.loadData();
        }else if($(e.target).text() === 'Modify'){
          if(id > 0 ){
            self.modifyDocument(id);
          }

        }else if($(e.target).text() == 'Remove'){
          if(id > 0 ) {
            self.removeDocument(id);
          }
        }else if($(e.target).text() == 'Send To QC'){
          if(id > 0 ) {
            self.showModal();
          }
        }
      }
    });

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#mytable>tbody>tr').show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    });

    $('[data-action=remove]').click(function (e) {
      $('#stitching_modal_window').modal('hide');
    });

    self.$data.from_date = moment().add(-7,'d').format('YYYY-MM-DD');

    // self.loadData();
    self.$modal.show('filter-window');

    $('#stitching_modal_window').on('shown.bs.modal', function (e) {
      self.isActive = true;

      $('#mytable').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });
    });

    $('#stitching_modal_window').on('hidden.bs.modal', function (e) {
      self.isActive = false;
      $('#mytable').unblock();
    });

  },
  methods: {
    afterfilterwindow_visible(){
      $('#txtfrom').focus();
    },
    showFilterWindow(){
      this.$modal.show('filter-window');
    },
    closeModal() {
      this.$modal.hide('filter-window');
      this.$modal.hide('stitching_modal_window');
    },
    beforeOpen(){
    },
    beforeClose(){
      this.voucher = JSON.parse('{"id":0,"finyear":2000,"status":"Active","cmp_id":1,"shift_id":1,"emp_id":1,"shift":{"id":0,"type":0,"name":""},"emp":{"id":0,"name":""},"doc_date":"0001-01-01","list":[],"total_pcs":0,"remarks":""}');
    },
    showModal () {
      const self = this;
      // this.$modal.show('stitching_modal_window');
      $('#stitching_modal_window').modal('show');
    },
    clear(){
      this.voucher = JSON.parse('{"id":0,"finyear":2000,"status":"Active","cmp_id":1,"shift_id":1,"emp_id":1,"shift":{"id":0,"type":0,"name":""},"emp":{"id":0,"name":""},"doc_date":"0001-01-01","list":[],"total_pcs":0,"remarks":""}');
    },
    loadEmps(){

      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };
      self.$data.emps = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {

        if (resp.ok){
          if(  !_.isNull(resp.data)) {

            self.$data.emps = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error"});
      });
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      self.voucher = JSON.parse('{"id":0,"finyear":2000,"status":"Active","cmp_id":1,"shift_id":1,"emp_id":1,"shift":{"id":0,"type":0,"name":""},"emp":{"id":0,"name":""},"doc_date":"0001-01-01","list":[],"total_pcs":0,"remarks":""}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/stitching/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok ) {

          if(!_.isNull(resp.data)){

            self.$data.voucher = resp.data;
            self.$data.voucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

            self.$modal.show('stitching_modal_window');
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
      });
    },
    removeDocument (id) {
      const self = this;

      self.$data.voucher.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.voucher)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete tms!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/stitching`,requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('stitching_modal_window')
            self.loadData();
            swal({title:'Deleted!', text:'Your request has been processed', type: 'success',  timer: 1500 });
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }
      });

    },
    detailFormatter(index, row) {
      let html = []
      $.each(row, function (key, value) {
        html.push('<p><b>' + key + ':</b> ' + value + '</p>')
      })
      return html.join('')
    },
    loadData () {
      const self = this;


      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$modal.hide('filter-window');
      this.$modal.hide('stitching_modal_window');

      $('#mytable').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.mytable.bootstrapTable('load',[]);

      let filters = `from=${self.from_date}&upto=${self.upto_date}&emp_id=${self.emp_id}`;

      // alert(`${process.env.VUE_APP_ROOT_API}api/stitchings?${filters}`);
      fetch(`${process.env.VUE_APP_ROOT_API}api/stitchings?${filters}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if(_.isArray(resp.data)){
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(()=>{
        $('#mytable').unblock();
      });
    },
  }
}
</script>

<style scoped>

  .modal-content {
    padding: 0px;
  }

  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
